/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import './src/assets/styles/fonts.scss';
// import './src/assets/styles/main.scss';
import "./src/App.scss";
import "./src/Font.scss";
import "./src/custom.scss";

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname, hash } = location;
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [
    `/`,
    `/faq`,
    `/corporate`,
    `/commute`,
    `/shuttle`,
    `/shuttle-driver`,
    `/shuttle-terms`,
  ];
  // if the new route is part of the list above and doesn't have #hash, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1 && !hash) {
    window.scrollTo(0, 0);
  }

  return false;
};
